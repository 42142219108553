import React from 'react';
import './footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="foot__container">
                <h1 className="footer__title">ColorLine</h1>
                <ul className="footer__list">
                    <li>
                        <a href="#about" className='footer__link'></a>
                    </li>
                    <li>
                        <a href="#about" className='footer__link'></a>
                    </li>
                    <li>
                        <a href="#about" className='footer__link'></a>
                    </li>
                </ul>
                <div className="footer__social">
                    <a href="" className="footer__social-link" target='_blank'>
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a href="" className="footer__social-link" target='_blank'>
                        <i class="bx bxl-linkedin"></i>
                    </a>

                    <a href="" className="footer__social-link" target='_blank'>
                        <i className="bx bxl-github"></i>
                    </a>
                </div>

                <span className="footer__copy">&#169; ColorLine. All rights reserved</span>
            </div>
        </footer>
    )
}

export default Footer