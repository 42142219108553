import React from 'react'

const Data = () => {
    return (
        <div className="home__data">
            <h1 className='home__title'><b className='title__cap'>C</b>olor<b className='title__cap'>L</b>ine</h1>
            
            <p className='home__description'>
            From dreams to reality
            </p>
        </div>
    )
}

export default Data